import Image from 'next/image';

export const LogoIcon = ({
  theme = null,
  width = null,
  height = null,
}: {
  theme?: 'black' | 'blue' | 'white' | null;
  width?: string | number | null;
  height?: string | number | null;
}) => {
  switch (theme) {
    case 'black':
      return (
        <Image
          alt="LouiseBot | AI Chatbot for Business"
          crossOrigin="anonymous"
          src="https://cdn.1cdn.app/application/LOUISEBOT/23091707385730_Logo_Black.svg"
          width={width ? parseInt(width as string) : 32}
          height={height ? parseInt(height as string) : 32}
          title="LouiseBot logo"
        />
      );
    case 'white':
      return (
        <Image
          alt="LouiseBot | AI Chatbot for Business"
          crossOrigin="anonymous"
          src="https://cdn.1cdn.app/application/LOUISEBOT/23091707385785_Logo_White.svg"
          width={width ? parseInt(width as string) : 32}
          height={height ? parseInt(height as string) : 32}
          title="LouiseBot logo"
        />
      );
    case 'blue':
      return (
        <Image
          alt="LouiseBot | AI Chatbot for Business"
          crossOrigin="anonymous"
          src="https://cdn.1cdn.app/application/LOUISEBOT/23091707385795_Logo_colorful.svg"
          width={width ? parseInt(width as string) : 32}
          height={height ? parseInt(height as string) : 32}
          title="LouiseBot logo"
        />
      );
    default:
      return (
        <Image
          alt="LouiseBot | AI Chatbot for Business"
          crossOrigin="anonymous"
          src="https://cdn.1cdn.app/application/LOUISEBOT/23091707385785_Logo_White.svg"
          width={width ? parseInt(width as string) : 32}
          height={height ? parseInt(height as string) : 32}
          title="LouiseBot logo"
        />
      );
  }
};
